<template>
    <div class="listCon">
        <div class="titlebar">
            <div class="titlename">{{ typeName ? typeName : "海洋观测标准规范" }}</div>
            <div class="total">
                共<span>{{ pagination?.total }}</span>条数据
            </div>
        </div>
        <el-row :gutter="10" style="margin-bottom: 20px;">
            <el-col :span="3"><div class="label">文件名称：</div></el-col>
            <el-col :span="17"><el-input placeholder="请输入文件名称" clearable size="small"  @clear="fetchData" v-model="searchForm.filedIndexesName"></el-input></el-col>
            <el-col :span="4"><el-button type="primary" size="small" @click="fetchData">搜索</el-button></el-col>
        </el-Row>
        <div class="cardList">
            <div class="empty" v-if="!baseData.length">
                <img src="../../assets/image/common/emtyp.png" />
                暂无数据
            </div>
            <div class="tableList" v-else>
                <el-table :data="baseData" border style="width: 100%">
                    <el-table-column prop="serialNumber" align="center" label="序号" width="50">
                    </el-table-column>
                    <el-table-column prop="filedIndexesName" align="left" label="资料名称">
                    </el-table-column>
                    <el-table-column label="操作" align="left" width="100">
                        <template slot-scope="scope">
                            <el-button @click="preview(scope.row)" type="text" size="small">预览</el-button>
                            <el-button @click="downloadData(scope.row)" type="text" size="small">下载</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- <Page :pagination="pagination" @handleCurrentChange="handleCurrentChange" /> -->
    </div>
</template>

<script>
import InfoCard from "./infoCard";
import Page from "@/components/Page";
import { fileLinkToStreamDownload } from '@/utils/utils.js'
export default {
    name: "List",
    props: ["dataType", "typeName"],
    components: {
        InfoCard,
        Page,
    },
    data() {
        return {
            searchForm: {
                filedIndexesName: "",
            },
            pagination: {
                current: 1,
                pageSize: 99999,
                total: 10,
            },
            baseData: [],
        };
    },
    watch: {
        dataType: function () {
            this.searchForm.filedIndexesName = ""
            this.fetchData();
        },
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        downloadData(record) {
            fileLinkToStreamDownload(record.file[0].httpAccessUrl, record.filedIndexesName, 'pdf')
        },
        preview(record) {
            window.open(record.file[0].httpAccessUrl);
        },
        prevClick(val) {
            this.pagination.current = val;
            this.fetchData();
        },
        handleCurrentChange(val) {
            this.pagination.current = val;
            this.fetchData();
        },
        fetchData(type) {
            if (type == 'search') {
                this.pagination.current = 1
            }
            if(type == 'clear'){
                this.searchForm.filedIndexesName = ""
            }
            let params = {
                ...this.pagination,
                filedType: this.dataType,
                filedIndexesName: this.searchForm.filedIndexesName,
                isOnShelf: 1
            };
            this.$request(
                "/documentationFileInfo/getDocumentationFileInfoPagingList",
                {
                    method: "post",
                    data: params,
                }
            ).then((res) => {
                if (res.errorCode == 0) {
                    this.baseData = null;
                    this.baseData = res.data.list;
                    this.pagination = res.data.pagination;
                }
            });
        },
        reset() {
            this.pagination.current = 1;
            this.searchForm.name = "";
            this.fetchData();
        },
    },
};
</script>

<style lang="less" scoped>
.listCon {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding-top: 20px;
    .label{
        font-size: 14px;
        color:#666;
        width: 100%;
        text-align: right;
        line-height: 28px;
    }
    .search {
        .handle {
            padding-top: 5px;
        }
        /deep/ .el-range-separator {
            padding: 0;
        }
    }
    > .titlebar {
        display: flex;
        height: 50px;
        background-color: #f5f6fa;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        margin: 0 20px 35px 20px;
        > .titlename {
            color: #1d78cd;
            font-size: 18px;
            font-weight: bold;
        }
        > .total {
            font-size: 16px;
            color: #666666;
            > span {
                color: #f3a611;
                margin: 0 5px;
            }
        }
    }
    > .cardList {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 10px 20px 10px;
        padding-bottom: 20px;
        > .empty {
            width: 100%;
            font-size: 16px;
            color: #666;
            text-align: center;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            > img {
                width: 120px;
                margin-bottom: 15px;
            }
        }
        > .tableList {
            margin: 0 auto;
            width: 100%;
        }
    }
}
</style>