<template>
    <div class="content">
        <div class="left">
            <LeftMenu :hideSerial="hideSerial" :titleIconUrl="titleIconUrl" titleName="标准规范" :subMenuData="menuList" @onDetail="checkList" />
        </div>
        <div class="right">
            <List :dataType="dataType" :typeName="typeName" />
        </div>
    </div>
</template>

<script>
import LeftMenu from "@/components/LeftMenu";
import List from './list.vue'
export default {
    name: "BaseData",
    components: {
        LeftMenu,
        List
    },
    data() {
        return {
            menuList: [],
            dataType: '',
            typeName: '',
            hideSerial: true,
            titleIconUrl: require("../../assets/basedata/icon_basedata.png")
        }
    },
    mounted() {
        this.$request("/dictionary/getDictionariesByType", {
            method: "post",
            data: {
                dictType: 'documentation_file_type'
            }
        }).then(res => {
            if (res.errorCode == 0) {
                let arr = []
                res.data.list.map((item, index) => {
                    arr.push({
                        ...item,
                        level: "5",
                        name: item.dictValue,
                        id: item.dictKey
                    })
                })
                this.menuList =arr
            }
        })
    },
    methods: {
        checkList(data) {
            this.dataType = data.dictKey
            this.typeName = data.dictValue
        }
    }

}
</script>

<style lang="less" scoped>
.content {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0px 0 30px 0;
    // margin: 0 auto;
    > .left {
        width: 280px;
        margin-right: 20px;
    }
    > .right {
        flex: 1;
    }
}
</style>