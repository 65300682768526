export function fileLinkToStreamDownload(url, fileName, type) {
    let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
    if (!reg.test(url)) {
        throw new Error("传入参数不合法,不是标准的文件链接");
    } else {
        let xhr = new XMLHttpRequest();
        xhr.open('get', url, true);
        xhr.setRequestHeader('Content-Type', `application/${type}`);
        xhr.responseType = "blob";
        xhr.onload = function () {
            if (this.status == 200) {
                //接受二进制文件流
                console.log(this)
                var blob = this.response;
                const blobUrl = window.URL.createObjectURL(blob);
                // 这里的文件名根据实际情况从响应头或者url里获取
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(blobUrl);
            }
        }
        xhr.send();
    }
}